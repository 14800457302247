import { registerSW } from "virtual:pwa-register";

registerSW({
  immediate: true,
  async onRegisteredSW(swScriptUrl, registration) {
    console.log("SW registered: ", swScriptUrl);

    if (!registration) {
      return null;
    }

    await Notification.requestPermission();
  },
  onOfflineReady() {
    console.log("PWA application ready to work offline");
  },
});
